import React from 'react';
import {
  //BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
//import logo from './logo.svg';
import './App.css';

import TimeTable from './components/TimeTable';
import Map from './components/Map';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<TimeTable />} exact={true} />
        <Route path="/timetable" element={<TimeTable />} exact={true} />
        <Route path="/map" element={<Map />} exact={true} />
      </Routes>
    </div>
  );
}

export default App;
