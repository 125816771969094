const locations = [
    {
        "id": 364,
        "name": "Åkrehamn",
        "lat": "59.266667",
        "lon": "5.183333",
        "info": null
    },
    {
        "id": 117,
        "name": "Ålesund",
        "lat": "62.472878",
        "lon": "6.149268",
        "info": "null"
    },
    {
        "id": 118,
        "name": "Alta",
        "lat": "69.966178",
        "lon": "23.275720",
        "info": "null"
    },
    {
        "id": 365,
        "name": "Åndalsnes",
        "lat": "62.567810",
        "lon": "7.689180",
        "info": null
    },
    {
        "id": 220,
        "name": "Andenes",
        "lat": "69.322131",
        "lon": "16.122234",
        "info": "null"
    },
    {
        "id": 119,
        "name": "Arendal",
        "lat": "58.460278",
        "lon": "8.766667",
        "info": "null"
    },
    {
        "id": 302,
        "name": "Årstein",
        "lat": "68.690277",
        "lon": "17.542222",
        "info": "null"
    },
    {
        "id": 120,
        "name": "Ås",
        "lat": "59.686430",
        "lon": "10.793011",
        "info": null
    },
    {
        "id": 121,
        "name": "Asker",
        "lat": "59.835234",
        "lon": "10.432976",
        "info": "null"
    },
    {
        "id": 122,
        "name": "Askim",
        "lat": "59.583333",
        "lon": "11.166667",
        "info": "null"
    },
    {
        "id": 221,
        "name": "Ballangen",
        "lat": "68.339560",
        "lon": "16.812300",
        "info": "null"
    },
    {
        "id": 225,
        "name": "Bardufoss",
        "lat": "69.069862",
        "lon": "18.522445",
        "info": "null"
    },
    {
        "id": 305,
        "name": "Batnfjordsøra",
        "lat": "62.892710",
        "lon": "7.681800",
        "info": null
    },
    {
        "id": 234,
        "name": "Båtsfjord",
        "lat": "70.634440",
        "lon": "29.718333",
        "info": "null"
    },
    {
        "id": 123,
        "name": "Bergen",
        "lat": "60.393477",
        "lon": "5.328903",
        "info": "null"
    },
    {
        "id": 226,
        "name": "Berlevåg",
        "lat": "70.857777",
        "lon": "29.086111",
        "info": "null"
    },
    {
        "id": 306,
        "name": "Birkeland",
        "lat": "58.328526",
        "lon": "8.231889",
        "info": null
    },
    {
        "id": 124,
        "name": "Bodø",
        "lat": "67.275373",
        "lon": "14.387663",
        "info": "null"
    },
    {
        "id": 228,
        "name": "Bogen",
        "lat": "68.526300",
        "lon": "16.993300",
        "info": "null"
    },
    {
        "id": 125,
        "name": "Borgen",
        "lat": "59.934621",
        "lon": "10.695638",
        "info": null
    },
    {
        "id": 229,
        "name": "Borkenes",
        "lat": "68.774250",
        "lon": "16.173010",
        "info": "null"
    },
    {
        "id": 307,
        "name": "Brandbu",
        "lat": "60.420833",
        "lon": "10.508333",
        "info": null
    },
    {
        "id": 230,
        "name": "Breivikbotn",
        "lat": "70.588611",
        "lon": "22.284722",
        "info": "null"
    },
    {
        "id": 308,
        "name": "Brekstad",
        "lat": "63.688659",
        "lon": "9.663968",
        "info": null
    },
    {
        "id": 309,
        "name": "Brevik",
        "lat": "59.052765",
        "lon": "9.700148",
        "info": null
    },
    {
        "id": 126,
        "name": "Brønnøysund",
        "lat": "65.465239",
        "lon": "12.204432",
        "info": "null"
    },
    {
        "id": 232,
        "name": "Brøstadbotn",
        "lat": "69.088330",
        "lon": "17.694440",
        "info": "null"
    },
    {
        "id": 127,
        "name": "Brumunddal",
        "lat": "60.880948",
        "lon": "10.939478",
        "info": null
    },
    {
        "id": 310,
        "name": "Bryne",
        "lat": "58.733333",
        "lon": "5.666667",
        "info": null
    },
    {
        "id": 233,
        "name": "Burfjord",
        "lat": "69.937770",
        "lon": "22.051944",
        "info": "null"
    },
    {
        "id": 311,
        "name": "Dalen",
        "lat": "59.446447",
        "lon": "7.986495",
        "info": null
    },
    {
        "id": 128,
        "name": "Drammen",
        "lat": "59.742500",
        "lon": "10.211667",
        "info": "null"
    },
    {
        "id": 312,
        "name": "Drøbak",
        "lat": "59.663333",
        "lon": "10.631111",
        "info": null
    },
    {
        "id": 313,
        "name": "Egersund",
        "lat": "58.451874",
        "lon": "6.002172",
        "info": null
    },
    {
        "id": 314,
        "name": "Eidsvoll",
        "lat": "60.329175",
        "lon": "11.258135",
        "info": null
    },
    {
        "id": 129,
        "name": "Elverum",
        "lat": "60.882968",
        "lon": "11.560230",
        "info": "null"
    },
    {
        "id": 235,
        "name": "Evenskjer",
        "lat": "68.584722",
        "lon": "16.575278",
        "info": "null"
    },
    {
        "id": 315,
        "name": "Fagernes",
        "lat": "60.986258",
        "lon": "9.236341",
        "info": null
    },
    {
        "id": 130,
        "name": "Farsund",
        "lat": "58.083333",
        "lon": "6.800000",
        "info": "null"
    },
    {
        "id": 236,
        "name": "Fauske",
        "lat": "67.260977",
        "lon": "15.398648",
        "info": "null"
    },
    {
        "id": 131,
        "name": "Finnsnes",
        "lat": "69.233334",
        "lon": "17.983330",
        "info": "null"
    },
    {
        "id": 316,
        "name": "Flekkefjord",
        "lat": "58.294155",
        "lon": "6.666206",
        "info": null
    },
    {
        "id": 317,
        "name": "Flisa",
        "lat": "60.605839",
        "lon": "12.015080",
        "info": null
    },
    {
        "id": 318,
        "name": "Flora",
        "lat": "61.566667",
        "lon": "5.133333",
        "info": null
    },
    {
        "id": 132,
        "name": "Florø",
        "lat": "61.600567",
        "lon": "5.032240",
        "info": "null"
    },
    {
        "id": 320,
        "name": "Førde",
        "lat": "61.451026",
        "lon": "5.853306",
        "info": null
    },
    {
        "id": 238,
        "name": "Forvika",
        "lat": "65.715300",
        "lon": "12.462900",
        "info": "null"
    },
    {
        "id": 319,
        "name": "Fosnavåg",
        "lat": "62.342253",
        "lon": "5.633350",
        "info": null
    },
    {
        "id": 133,
        "name": "Fredrikstad",
        "lat": "59.216669",
        "lon": "10.949992",
        "info": "null"
    },
    {
        "id": 321,
        "name": "Geilo",
        "lat": "60.533276",
        "lon": "8.207681",
        "info": null
    },
    {
        "id": 134,
        "name": "Gjøvik",
        "lat": "60.798700",
        "lon": "10.693762",
        "info": "null"
    },
    {
        "id": 239,
        "name": "Gladstad",
        "lat": "65.676800",
        "lon": "11.962200",
        "info": "null"
    },
    {
        "id": 322,
        "name": "Gol",
        "lat": "60.703333",
        "lon": "8.951667",
        "info": null
    },
    {
        "id": 135,
        "name": "Gran",
        "lat": "60.355715",
        "lon": "10.573593",
        "info": null
    },
    {
        "id": 136,
        "name": "Grimstad",
        "lat": "58.341399",
        "lon": "8.580881",
        "info": "null"
    },
    {
        "id": 137,
        "name": "Grong",
        "lat": "64.464004",
        "lon": "12.314300",
        "info": null
    },
    {
        "id": 138,
        "name": "Hagan",
        "lat": "60.042844",
        "lon": "10.880393",
        "info": null
    },
    {
        "id": 139,
        "name": "Halden",
        "lat": "59.124280",
        "lon": "11.388153",
        "info": "null"
    },
    {
        "id": 140,
        "name": "Hamar",
        "lat": "60.795597",
        "lon": "11.074605",
        "info": "null"
    },
    {
        "id": 141,
        "name": "Hammerfest",
        "lat": "70.663633",
        "lon": "23.679121",
        "info": "null"
    },
    {
        "id": 241,
        "name": "Hamnvik",
        "lat": "68.778055",
        "lon": "17.174444",
        "info": "null"
    },
    {
        "id": 242,
        "name": "Hansnes",
        "lat": "69.967000",
        "lon": "19.627500",
        "info": "null"
    },
    {
        "id": 142,
        "name": "Harstad",
        "lat": "68.799903",
        "lon": "16.520384",
        "info": "null"
    },
    {
        "id": 244,
        "name": "Hatteng",
        "lat": "69.270550",
        "lon": "19.959440",
        "info": "null"
    },
    {
        "id": 245,
        "name": "Hattfjelldal",
        "lat": "65.597400",
        "lon": "13.987900",
        "info": "null"
    },
    {
        "id": 143,
        "name": "Haugesund",
        "lat": "59.409699",
        "lon": "5.269838",
        "info": "null"
    },
    {
        "id": 246,
        "name": "Havøysund",
        "lat": "70.996111",
        "lon": "24.661944",
        "info": "null"
    },
    {
        "id": 144,
        "name": "Heimdal",
        "lat": "63.350835",
        "lon": "10.357723",
        "info": null
    },
    {
        "id": 145,
        "name": "Hokksund",
        "lat": "59.770079",
        "lon": "9.910096",
        "info": "null"
    },
    {
        "id": 323,
        "name": "Holmestrand",
        "lat": "59.488333",
        "lon": "10.302778",
        "info": null
    },
    {
        "id": 146,
        "name": "Hønefoss",
        "lat": "60.166700",
        "lon": "10.255085",
        "info": "null"
    },
    {
        "id": 247,
        "name": "Honningsvåg",
        "lat": "70.982715",
        "lon": "25.974378",
        "info": "null"
    },
    {
        "id": 147,
        "name": "Horten",
        "lat": "59.416983",
        "lon": "10.484132",
        "info": "null"
    },
    {
        "id": 324,
        "name": "Husnes",
        "lat": "59.860936",
        "lon": "5.751641",
        "info": null
    },
    {
        "id": 248,
        "name": "Husøya",
        "lat": "66.495400",
        "lon": "12.074900",
        "info": "null"
    },
    {
        "id": 148,
        "name": "Husøysund",
        "lat": "59.239339",
        "lon": "10.563303",
        "info": null
    },
    {
        "id": 249,
        "name": "Inndyr",
        "lat": "67.033500",
        "lon": "14.026600",
        "info": "null"
    },
    {
        "id": 149,
        "name": "Jakobsli",
        "lat": "63.417080",
        "lon": "10.496057",
        "info": null
    },
    {
        "id": 150,
        "name": "Jessheim",
        "lat": "60.144590",
        "lon": "11.172460",
        "info": "null"
    },
    {
        "id": 325,
        "name": "Jørpeland",
        "lat": "59.016667",
        "lon": "6.050000",
        "info": null
    },
    {
        "id": 250,
        "name": "Karasjok",
        "lat": "69.471900",
        "lon": "25.511200",
        "info": "null"
    },
    {
        "id": 251,
        "name": "Kautokeino",
        "lat": "69.011099",
        "lon": "23.036831",
        "info": "null"
    },
    {
        "id": 151,
        "name": "Kirkenes",
        "lat": "69.726334",
        "lon": "30.049774",
        "info": "null"
    },
    {
        "id": 253,
        "name": "Kjøllefjord",
        "lat": "70.945500",
        "lon": "27.346388",
        "info": "null"
    },
    {
        "id": 152,
        "name": "Kleppe",
        "lat": "58.773026",
        "lon": "5.633311",
        "info": null
    },
    {
        "id": 326,
        "name": "Kongsberg",
        "lat": "59.669320",
        "lon": "9.651510",
        "info": null
    },
    {
        "id": 153,
        "name": "Kongsvinger",
        "lat": "60.186673",
        "lon": "12.005030",
        "info": "null"
    },
    {
        "id": 327,
        "name": "Kopervik",
        "lat": "59.283333",
        "lon": "5.300000",
        "info": null
    },
    {
        "id": 254,
        "name": "Korgen",
        "lat": "66.076600",
        "lon": "13.821600",
        "info": "null"
    },
    {
        "id": 154,
        "name": "Kragerø",
        "lat": "58.866667",
        "lon": "9.416667",
        "info": "null"
    },
    {
        "id": 328,
        "name": "Kristiansand",
        "lat": "58.146704",
        "lon": "7.995770",
        "info": null
    },
    {
        "id": 156,
        "name": "Kristiansand N",
        "lat": "58.159912",
        "lon": "8.018206",
        "info": null
    },
    {
        "id": 155,
        "name": "Kristiansand S",
        "lat": "58.159912",
        "lon": "8.018206",
        "info": null
    },
    {
        "id": 329,
        "name": "Kristiansund",
        "lat": "63.115065",
        "lon": "7.733290",
        "info": null
    },
    {
        "id": 330,
        "name": "Krokstadøra",
        "lat": "63.400400",
        "lon": "9.502300",
        "info": null
    },
    {
        "id": 157,
        "name": "Kvinesdal",
        "lat": "58.338056",
        "lon": "7.023056",
        "info": "null"
    },
    {
        "id": 255,
        "name": "Lakselv",
        "lat": "70.052466",
        "lon": "24.963639",
        "info": "null"
    },
    {
        "id": 158,
        "name": "Langesund",
        "lat": "59.000776",
        "lon": "9.748951",
        "info": "null"
    },
    {
        "id": 159,
        "name": "Larvik",
        "lat": "59.054328",
        "lon": "10.029269",
        "info": "null"
    },
    {
        "id": 331,
        "name": "Leikanger",
        "lat": "61.189673",
        "lon": "6.857142",
        "info": null
    },
    {
        "id": 256,
        "name": "Leinesfjord",
        "lat": "67.774600",
        "lon": "15.016100",
        "info": "null"
    },
    {
        "id": 332,
        "name": "Leirvik",
        "lat": "59.779512",
        "lon": "5.500249",
        "info": null
    },
    {
        "id": 160,
        "name": "Leknes",
        "lat": "68.145392",
        "lon": "13.610020",
        "info": "null"
    },
    {
        "id": 333,
        "name": "Leksvik",
        "lat": "63.673583",
        "lon": "10.627194",
        "info": null
    },
    {
        "id": 258,
        "name": "Leland",
        "lat": "66.064100",
        "lon": "12.943300",
        "info": "null"
    },
    {
        "id": 161,
        "name": "Levanger",
        "lat": "63.745977",
        "lon": "11.299825",
        "info": "null"
    },
    {
        "id": 334,
        "name": "Lierne",
        "lat": "64.466667",
        "lon": "13.591667",
        "info": null
    },
    {
        "id": 162,
        "name": "Lierstranda",
        "lat": "59.757472",
        "lon": "10.262079",
        "info": null
    },
    {
        "id": 163,
        "name": "Lillehammer",
        "lat": "61.114431",
        "lon": "10.466330",
        "info": "null"
    },
    {
        "id": 164,
        "name": "Lillesand",
        "lat": "58.252223",
        "lon": "8.378333",
        "info": "null"
    },
    {
        "id": 165,
        "name": "Lillestrøm",
        "lat": "59.956725",
        "lon": "11.049356",
        "info": "null"
    },
    {
        "id": 261,
        "name": "Lødingen",
        "lat": "68.413700",
        "lon": "15.996300",
        "info": "null"
    },
    {
        "id": 335,
        "name": "Lom",
        "lat": "61.837443",
        "lon": "8.568408",
        "info": null
    },
    {
        "id": 336,
        "name": "Longyearbyen",
        "lat": "78.216667",
        "lon": "15.633333",
        "info": null
    },
    {
        "id": 166,
        "name": "Lørenskog",
        "lat": "59.923799",
        "lon": "10.954183",
        "info": null
    },
    {
        "id": 337,
        "name": "Lovund",
        "lat": "66.368250",
        "lon": "12.360417",
        "info": null
    },
    {
        "id": 259,
        "name": "Lurøy",
        "lat": "66.431940",
        "lon": "12.855000",
        "info": "null"
    },
    {
        "id": 167,
        "name": "Lyngdal",
        "lat": "58.133333",
        "lon": "7.083333",
        "info": "null"
    },
    {
        "id": 260,
        "name": "Lyngseidet",
        "lat": "69.576300",
        "lon": "20.218800",
        "info": "null"
    },
    {
        "id": 168,
        "name": "Lysekloster",
        "lat": "60.227624",
        "lon": "5.405381",
        "info": null
    },
    {
        "id": 338,
        "name": "Måløy",
        "lat": "61.938333",
        "lon": "5.113333",
        "info": null
    },
    {
        "id": 169,
        "name": "Mandal",
        "lat": "58.026767",
        "lon": "7.452446",
        "info": "null"
    },
    {
        "id": 262,
        "name": "Mehamn",
        "lat": "71.035550",
        "lon": "27.849166",
        "info": "null"
    },
    {
        "id": 170,
        "name": "Melbu",
        "lat": "68.503250",
        "lon": "14.812440",
        "info": "null"
    },
    {
        "id": 171,
        "name": "Mjøndalen",
        "lat": "59.749763",
        "lon": "10.014191",
        "info": null
    },
    {
        "id": 265,
        "name": "Moen",
        "lat": "69.130278",
        "lon": "18.612222",
        "info": "null"
    },
    {
        "id": 172,
        "name": "Mo i Rana",
        "lat": "66.316667",
        "lon": "14.166667",
        "info": "null"
    },
    {
        "id": 173,
        "name": "Molde",
        "lat": "62.737884",
        "lon": "7.158957",
        "info": "null"
    },
    {
        "id": 266,
        "name": "Moldjord",
        "lat": "67.006700",
        "lon": "14.576400",
        "info": "null"
    },
    {
        "id": 267,
        "name": "Mosjøen",
        "lat": "65.837654",
        "lon": "13.194319",
        "info": "null"
    },
    {
        "id": 174,
        "name": "Moss",
        "lat": "59.434797",
        "lon": "10.661975",
        "info": "null"
    },
    {
        "id": 268,
        "name": "Myre",
        "lat": "68.914000",
        "lon": "15.078400",
        "info": "null"
    },
    {
        "id": 175,
        "name": "Mysen",
        "lat": "59.555664",
        "lon": "11.323218",
        "info": "null"
    },
    {
        "id": 339,
        "name": "Nærøy",
        "lat": "64.816667",
        "lon": "11.250000",
        "info": null
    },
    {
        "id": 176,
        "name": "Namsos",
        "lat": "64.469427",
        "lon": "11.509952",
        "info": "null"
    },
    {
        "id": 177,
        "name": "Narvik",
        "lat": "68.439679",
        "lon": "17.426652",
        "info": "null"
    },
    {
        "id": 270,
        "name": "Nesna",
        "lat": "66.198100",
        "lon": "13.018510",
        "info": "null"
    },
    {
        "id": 178,
        "name": "Nordfjordeid",
        "lat": "61.906263",
        "lon": "5.991555",
        "info": null
    },
    {
        "id": 179,
        "name": "Notodden",
        "lat": "59.562877",
        "lon": "9.264255",
        "info": "null"
    },
    {
        "id": 340,
        "name": "Odda",
        "lat": "60.066667",
        "lon": "6.550000",
        "info": null
    },
    {
        "id": 303,
        "name": "Øksfjord",
        "lat": "70.239400",
        "lon": "22.350700",
        "info": "null"
    },
    {
        "id": 271,
        "name": "Olderdalen",
        "lat": "69.603880",
        "lon": "20.532500",
        "info": "null"
    },
    {
        "id": 272,
        "name": "Oppeid",
        "lat": "68.085200",
        "lon": "15.609400",
        "info": "null"
    },
    {
        "id": 180,
        "name": "Orkanger",
        "lat": "63.305028",
        "lon": "9.849737",
        "info": "null"
    },
    {
        "id": 304,
        "name": "Ørnes",
        "lat": "66.868800",
        "lon": "13.705800",
        "info": "null"
    },
    {
        "id": 366,
        "name": "Ørsta",
        "lat": "62.200000",
        "lon": "6.133333",
        "info": null
    },
    {
        "id": 181,
        "name": "Oslo",
        "lat": "59.913263",
        "lon": "10.739122",
        "info": "null"
    },
    {
        "id": 182,
        "name": "Otta",
        "lat": "61.771210",
        "lon": "9.539438",
        "info": "null"
    },
    {
        "id": 184,
        "name": "Porsgrunn",
        "lat": "59.150000",
        "lon": "9.666667",
        "info": "null"
    },
    {
        "id": 185,
        "name": "Rakkestad",
        "lat": "59.425546",
        "lon": "11.343039",
        "info": null
    },
    {
        "id": 273,
        "name": "Ramberg",
        "lat": "68.089900",
        "lon": "13.229900",
        "info": "null"
    },
    {
        "id": 341,
        "name": "Rasvag",
        "lat": "58.212408",
        "lon": "6.580929",
        "info": null
    },
    {
        "id": 342,
        "name": "Raufoss",
        "lat": "60.728039",
        "lon": "10.613526",
        "info": null
    },
    {
        "id": 274,
        "name": "Reine",
        "lat": "67.932400",
        "lon": "13.088700",
        "info": "null"
    },
    {
        "id": 343,
        "name": "Rennebu",
        "lat": "62.830833",
        "lon": "9.875000",
        "info": null
    },
    {
        "id": 344,
        "name": "Risør",
        "lat": "58.716657",
        "lon": "9.232800",
        "info": null
    },
    {
        "id": 187,
        "name": "Rjukan",
        "lat": "59.881788",
        "lon": "8.588232",
        "info": "null"
    },
    {
        "id": 275,
        "name": "Rognan",
        "lat": "67.095300",
        "lon": "15.387810",
        "info": "null"
    },
    {
        "id": 345,
        "name": "Røldal",
        "lat": "59.828349",
        "lon": "6.815793",
        "info": null
    },
    {
        "id": 346,
        "name": "Røros",
        "lat": "62.574930",
        "lon": "11.381913",
        "info": null
    },
    {
        "id": 276,
        "name": "Røstlandet",
        "lat": "67.524300",
        "lon": "12.115200",
        "info": "null"
    },
    {
        "id": 347,
        "name": "Røyrvik",
        "lat": "64.883333",
        "lon": "13.562500",
        "info": null
    },
    {
        "id": 348,
        "name": "Sand",
        "lat": "59.484565",
        "lon": "6.259782",
        "info": null
    },
    {
        "id": 188,
        "name": "Sandane",
        "lat": "61.741667",
        "lon": "6.170833",
        "info": "null"
    },
    {
        "id": 190,
        "name": "Sandefjord",
        "lat": "59.133333",
        "lon": "10.233333",
        "info": "null"
    },
    {
        "id": 189,
        "name": "Sande i Vestfold",
        "lat": "59.586792",
        "lon": "10.208022",
        "info": null
    },
    {
        "id": 191,
        "name": "Sandnes",
        "lat": "58.851674",
        "lon": "5.736719",
        "info": "null"
    },
    {
        "id": 277,
        "name": "Sandnessjøen",
        "lat": "66.022228",
        "lon": "12.636439",
        "info": "null"
    },
    {
        "id": 192,
        "name": "Sandvika",
        "lat": "59.891670",
        "lon": "10.524713",
        "info": "null"
    },
    {
        "id": 193,
        "name": "Sarpsborg",
        "lat": "59.283333",
        "lon": "11.116667",
        "info": "null"
    },
    {
        "id": 349,
        "name": "Sauda",
        "lat": "59.649996",
        "lon": "6.333326",
        "info": null
    },
    {
        "id": 194,
        "name": "Saupstad",
        "lat": "63.366125",
        "lon": "10.349237",
        "info": null
    },
    {
        "id": 278,
        "name": "Setermoen",
        "lat": "68.861721",
        "lon": "18.343972",
        "info": "null"
    },
    {
        "id": 279,
        "name": "Silvalen",
        "lat": "65.983200",
        "lon": "12.288600",
        "info": "null"
    },
    {
        "id": 280,
        "name": "Sjøvegan",
        "lat": "68.873611",
        "lon": "17.846944",
        "info": "null"
    },
    {
        "id": 195,
        "name": "Ski",
        "lat": "59.716664",
        "lon": "10.833330",
        "info": "null"
    },
    {
        "id": 196,
        "name": "Skien",
        "lat": "59.207622",
        "lon": "9.609466",
        "info": "null"
    },
    {
        "id": 197,
        "name": "Skjeberg",
        "lat": "59.211471",
        "lon": "11.190285",
        "info": null
    },
    {
        "id": 281,
        "name": "Skjervøy",
        "lat": "70.031111",
        "lon": "20.971389",
        "info": "null"
    },
    {
        "id": 350,
        "name": "Skudeneshavn",
        "lat": "59.150000",
        "lon": "5.283333",
        "info": null
    },
    {
        "id": 198,
        "name": "Skui",
        "lat": "59.930962",
        "lon": "10.448151",
        "info": null
    },
    {
        "id": 351,
        "name": "Sogndal",
        "lat": "61.231678",
        "lon": "7.103836",
        "info": null
    },
    {
        "id": 352,
        "name": "Sogne",
        "lat": "58.095462",
        "lon": "7.809787",
        "info": null
    },
    {
        "id": 282,
        "name": "Solfjellsjøen",
        "lat": "66.109000",
        "lon": "12.490500",
        "info": "null"
    },
    {
        "id": 290,
        "name": "Sørland",
        "lat": "67.665600",
        "lon": "12.697800",
        "info": "null"
    },
    {
        "id": 291,
        "name": "Sørreisa",
        "lat": "69.144722",
        "lon": "18.152777",
        "info": "null"
    },
    {
        "id": 283,
        "name": "Sortland",
        "lat": "68.705422",
        "lon": "15.414362",
        "info": "null"
    },
    {
        "id": 199,
        "name": "Stange",
        "lat": "60.717457",
        "lon": "11.193590",
        "info": null
    },
    {
        "id": 353,
        "name": "Stathelle",
        "lat": "59.046010",
        "lon": "9.697930",
        "info": null
    },
    {
        "id": 200,
        "name": "Stavanger",
        "lat": "58.968036",
        "lon": "5.732483",
        "info": "null"
    },
    {
        "id": 354,
        "name": "Stavern",
        "lat": "58.998500",
        "lon": "10.035942",
        "info": null
    },
    {
        "id": 355,
        "name": "Steinkjer",
        "lat": "64.018578",
        "lon": "11.493800",
        "info": null
    },
    {
        "id": 201,
        "name": "Stjørdal",
        "lat": "63.469830",
        "lon": "10.923068",
        "info": "null"
    },
    {
        "id": 284,
        "name": "Stokmarknes",
        "lat": "68.561944",
        "lon": "14.908611",
        "info": "null"
    },
    {
        "id": 202,
        "name": "Stord",
        "lat": "59.788889",
        "lon": "5.511667",
        "info": "null"
    },
    {
        "id": 285,
        "name": "Storslett",
        "lat": "69.769600",
        "lon": "21.025300",
        "info": "null"
    },
    {
        "id": 286,
        "name": "Storsteinnes",
        "lat": "69.240700",
        "lon": "19.234500",
        "info": "null"
    },
    {
        "id": 288,
        "name": "Straume",
        "lat": "68.689000",
        "lon": "14.471500",
        "info": "null"
    },
    {
        "id": 287,
        "name": "Straumen",
        "lat": "67.348100",
        "lon": "15.597200",
        "info": "null"
    },
    {
        "id": 203,
        "name": "Strømmen",
        "lat": "59.945765",
        "lon": "11.007263",
        "info": null
    },
    {
        "id": 357,
        "name": "Strömstad",
        "lat": "58.939214",
        "lon": "11.170365",
        "info": null
    },
    {
        "id": 356,
        "name": "Stryn",
        "lat": "61.904056",
        "lon": "6.724000",
        "info": null
    },
    {
        "id": 204,
        "name": "Sunndalsøra",
        "lat": "62.675189",
        "lon": "8.563269",
        "info": "null"
    },
    {
        "id": 358,
        "name": "Svelvik",
        "lat": "59.610833",
        "lon": "10.405828",
        "info": null
    },
    {
        "id": 205,
        "name": "Svolvær",
        "lat": "68.234772",
        "lon": "14.564736",
        "info": "null"
    },
    {
        "id": 292,
        "name": "Tana Bru",
        "lat": "70.198600",
        "lon": "28.191900",
        "info": "null"
    },
    {
        "id": 293,
        "name": "Tennevoll",
        "lat": "68.747100",
        "lon": "17.806200",
        "info": "null"
    },
    {
        "id": 294,
        "name": "Terråk",
        "lat": "65.085000",
        "lon": "12.369200",
        "info": "null"
    },
    {
        "id": 206,
        "name": "Tønsberg",
        "lat": "59.285833",
        "lon": "10.418056",
        "info": "null"
    },
    {
        "id": 207,
        "name": "Tranby",
        "lat": "59.808169",
        "lon": "10.266683",
        "info": null
    },
    {
        "id": 295,
        "name": "Trofors",
        "lat": "65.533400",
        "lon": "13.406300",
        "info": "null"
    },
    {
        "id": 208,
        "name": "Tromsø",
        "lat": "69.666986",
        "lon": "18.951797",
        "info": "null"
    },
    {
        "id": 209,
        "name": "Trondheim",
        "lat": "63.426321",
        "lon": "10.395814",
        "info": "null"
    },
    {
        "id": 359,
        "name": "Tvedestrand",
        "lat": "58.624444",
        "lon": "8.928611",
        "info": null
    },
    {
        "id": 210,
        "name": "Tynset",
        "lat": "62.277515",
        "lon": "10.776966",
        "info": "null"
    },
    {
        "id": 211,
        "name": "Ulsteinvik",
        "lat": "62.346195",
        "lon": "5.854322",
        "info": "null"
    },
    {
        "id": 212,
        "name": "Vadsø",
        "lat": "70.074358",
        "lon": "29.752481",
        "info": "null"
    },
    {
        "id": 301,
        "name": "Vågaholmen",
        "lat": "66.713600",
        "lon": "13.287500",
        "info": "null"
    },
    {
        "id": 213,
        "name": "Våler i Østfold",
        "lat": "59.453046",
        "lon": "10.933007",
        "info": null
    },
    {
        "id": 360,
        "name": "Vang i Valdres",
        "lat": "61.125000",
        "lon": "8.571667",
        "info": null
    },
    {
        "id": 361,
        "name": "Vanningssundet",
        "lat": "58.715443",
        "lon": "9.116602",
        "info": null
    },
    {
        "id": 298,
        "name": "Varangerbotn",
        "lat": "70.172500",
        "lon": "28.555833",
        "info": "null"
    },
    {
        "id": 299,
        "name": "Vardø",
        "lat": "70.366667",
        "lon": "31.100000",
        "info": "null"
    },
    {
        "id": 214,
        "name": "Vennesla",
        "lat": "58.269868",
        "lon": "7.966611",
        "info": "null"
    },
    {
        "id": 362,
        "name": "Verdalsøra",
        "lat": "63.800000",
        "lon": "11.483333",
        "info": null
    },
    {
        "id": 300,
        "name": "Vik",
        "lat": "65.312500",
        "lon": "12.167300",
        "info": "null"
    },
    {
        "id": 215,
        "name": "Vinje",
        "lat": "59.807138",
        "lon": "7.713770",
        "info": null
    },
    {
        "id": 216,
        "name": "Vinterbro",
        "lat": "59.742407",
        "lon": "10.774236",
        "info": null
    },
    {
        "id": 217,
        "name": "Volda",
        "lat": "62.087500",
        "lon": "6.016667",
        "info": "null"
    },
    {
        "id": 218,
        "name": "Voss",
        "lat": "60.629105",
        "lon": "6.410115",
        "info": null
    },
    {
        "id": 363,
        "name": "Vossevangen",
        "lat": "60.628651",
        "lon": "6.419105",
        "info": null
    },
    {
        "id": 219,
        "name": "Vøyenenga",
        "lat": "59.907802",
        "lon": "10.475770",
        "info": null
    },
    {
        "id": 390,
        "name": "Vormsund",
        "lat": "60.153330",
        "lon": "11.415830",
        "info": null
    },
    {
        "id": 389,
        "name": "Oppdal",
        "lat": "62.566670",
        "lon": "9.600000",
        "info": null
    }
]
export default locations;