import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import Plot from 'react-plotly.js';

import Locations from './Locations';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

function Map() {

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    })

    let lonLoc = [];
    let latLoc = [];
    let textLoc = [];

    Locations.forEach((data) => {
        lonLoc.push(parseFloat(data.lon));
        latLoc.push(parseFloat(data.lat));
        textLoc.push(data.name);
    })

    var dataM = [{ type: 'scattermapbox', lon: lonLoc, lat: latLoc, text: textLoc, hoverinfo: "text+lat+lon" }];
    var layoutM = {
        width: Math.round(0.95 * dimensions.width),
        height: Math.round(0.95 * dimensions.height),
        mapbox: {
            style: 'open-street-map',
            center: { lat: 65.5, lon: 17 },
            zoom: 4.3,
        },
        margin: { r: 0, t: 0, b: 0, l: 0 },
    };
    return (
        <div>
            {/* <Button variant="link" onClick={() => window.close()}>tilbake til Bønnetider</Button> */}
            <Button variant="link" onClick={() => window.open("/timetable")}>tilbake til Bønnetider</Button>
            <Container className="m-0 p-0 bg-primary">
                <Row className="m-0 p-0 bg-light">
                    <Col className="m-0 p-0 w-100">
                        <Plot data={dataM} layout={layoutM} />
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    )
}

export default Map;